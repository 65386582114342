body {
    --darkprimary-color: #35245D;
   /* --primary-color: #642EE4;  Faglimar mudou para a cor abaixo e criou a primary-colorOLD*/
    --primary-colorOLD: #4BB6FB;
    --roxoescuro-color: #342062;
    --green-color: #1AC78B;
    --primary-color: #8658F2;
    --secondary-color: #FD2FBC;
    --accent-color: #4BB6FB;
    

    --white-color: #ffffff;
    --black-color: #000000;
    --grey-color: #4C4C4C;
    --backgroundgrey-color: #F7F7F7;
    --lightgrey-color: #A4A4A4;
    --lg-color: #B9B9B9;
    --shadow-color: rgba(0,0,0,.16);
    --whiteshadow-color: rgba(255,255,255,.14);
    --lightshadow-color: rgba(112,112,112,.06);
    --yellow-color: #FFCC00;
}